<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="groupsStore.isLoadingModal" />
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-4">
          <h1 class="text-3xl text-black font-semibold">Lives</h1>
          <h1 class="text-md text-gray font-semibold mt-2">
            Manage your lives
          </h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          New live for group: {{ this.group?.name }}
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">Live title</p>
              <input
                v-model="live_title"
                :class="
                  v$.live_title.$error ? 'border-red-500 border-[3px]' : ''
                "
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500 text-[1rem]" v-if="v$.live_title.$error">
                <span
                  v-for="(error, index) in v$.live_title.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Price</p>
              <input
                v-model="price"
                min="0"
                type="number"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
            </div>
          </div>
          <div class="md:flex">
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Start date</p>
              <VueDatePicker
                v-model="start_date"
                :min-date="getToday()"
                :format="'yyyy-MM-dd HH:mm'"
                :enable-time-picker="true"
                time-picker-inline
              />

              <p class="text-red-500 text-[1rem]" v-if="v$.start_date.$error">
                <span
                  v-for="(error, index) in v$.start_date.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Duration</p>
              <VueDatePicker
                class="time-picker"
                v-model="duration"
                :start-time="{ hours: 1, minutes: 30 }"
                time-picker
              />

              <p class="text-red-500 text-[1rem]" v-if="v$.duration.$error">
                <span
                  v-for="(error, index) in v$.duration.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>
          <div class="md:flex justify-evenly">
            <div class="my-2 mb-4 text-xl rounded-md">
              <p class="text-purple mb-1 text-left">Background</p>
              <label
                class="cursor-pointer w-fit flex items-center"
                for="inputTag"
              >
                <div
                  v-if="!this.background"
                  class="flex flex-col upload-block justify-center items-center p-2 h-40 w-40 rounded-md border-dashed"
                >
                  <p class="text-[#8a8a8ac9] mb-1 text-center text-base mt-2">
                    Click here to select a file
                  </p>

                  <input
                    class="hidden"
                    hidden
                    id="inputTag"
                    @change="handleBackgroundChange"
                    type="file"
                    accept="image/*"
                  />
                </div>

                <img
                  v-show="this.background"
                  class="h-40 w-40"
                  ref="background"
                />
                <img
                  @click="removeBackground"
                  alt="bbb"
                  :src="trash"
                  class="ml-2"
                  v-if="this.background"
                  width="40"
                />
              </label>
            </div>
            <div class="my-2 mb-4 text-xl rounded-md">
              <p class="text-purple mb-1 text-left">Cover</p>
              <label
                class="cursor-pointer w-fit flex items-center"
                for="inputTag"
              >
                <div
                  v-if="!this.cover"
                  class="flex flex-col upload-block justify-center items-center p-2 h-40 w-40 rounded-md border-dashed"
                >
                  <p class="text-[#8a8a8ac9] mb-1 text-center text-base mt-2">
                    Click here to select a file
                  </p>

                  <input
                    class="hidden"
                    hidden
                    id="inputTag"
                    @change="handleCoverChange"
                    type="file"
                    accept="image/*"
                  />
                </div>

                <img v-show="this.cover" class="h-40 w-40" ref="output" />
                <img
                  @click="removeImage"
                  alt="bbb"
                  :src="trash"
                  class="ml-2"
                  v-if="this.cover"
                  width="40"
                />
              </label>
            </div>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="addLive">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import { useLivesStore } from "@/store/lives";
import trash from "@/assets/icons/cards/trash.svg";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import router from "@/router";
import { useTeachersStore } from "@/store/teachers";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useGroupsStore } from "@/store/groups";

export default {
  data() {
    return {
      group: null,
      live_title: "",
      destination: "",
      duration: "",
      timing: "",
      start_date: null,
      cover: null,
      price: null,
      background: null,
      password: "",
      showPassword: false,
      isPasswordFocus: false,
      isEmailFocus: false,
      currentTeacher: null,
    };
  },
  setup() {
    const livesStore = useLivesStore();
    const teachersStore = useTeachersStore();
    const groupsStore = useGroupsStore();

    return {
      v$: useVuelidate(),
      livesStore,
      teachersStore,
      groupsStore,
      trash,
    };
  },
  methods: {
    async addLive() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      } else {
        const formData = new FormData();
        formData.append("title", this.live_title);
        formData.append("group_id", this.group.id);
        formData.append("teacher_id", this.group.teacher_id);
        if (this.cover instanceof File) formData.append("cover", this.cover);
        if (this.background instanceof File)
          formData.append("background", this.background);
        formData.append("start_date", this.dateFormat(this.start_date));
        formData.append("duration", this.durationFormat(this.duration));
        if (this.price) {
          formData.append("price", this.price);
        }
        await this.livesStore.addLive(formData, this.$route.params.id);

        if (this.livesStore.isSuccess) {
          this.$swal({
            icon: "success",
            timer: 1500,
            title: "Success...",
            showConfirmButton: false,
            html: "<p class='text-green-500' > Live aded successfully<p/>",
          }).then(() => {
            router.push("/admin/lives");
          });
        } else if (this.livesStore.isFail) {
          this.$swal({
            icon: "error",
            title: "Error",
            showConfirmButton: true,
            timer: 4000,
            html: `<ul>
            ${this.livesStore.errorMessages.map((error) => {
              return error.map((message) => {
                return `<li class="text-red-500">
                      ${message}
                    </li>`;
              });
            })}
          </ul>`,
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        this.livesStore.isSuccess = false;
        this.livesStore.isFail = false;
      }
      // perform async actions
    },
    handleCoverChange(event) {
      this.cover = event.target.files[0];
      let outputPic = this.$refs.output;
      outputPic.src = URL.createObjectURL(this.cover);
      outputPic.width = 200;
    },
    handleBackgroundChange(event) {
      this.background = event.target.files[0];
      let outputPic = this.$refs.background;
      outputPic.src = URL.createObjectURL(this.background);
      outputPic.width = 200;
    },

    removeImage() {
      this.$refs.output.src = null;
      this.cover = null;
      // this.categoriesStore.editedCategory.image = null;
    },

    removeBackground() {
      this.$refs.background.src = null;
      this.background = null;
    },
    getToday() {
      const today = new Date();
      today.setDate(today.getDate());
      return today;
    },

    dateFormat(newDate) {
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();

      // Add leading zeros if necessary
      const formattedMonth = parseInt(month) < 10 ? `0${month}` : month;
      const formattedDay = parseInt(day) < 10 ? `0${day}` : day;
      const formattedHours = parseInt(hours) < 10 ? `0${hours}` : hours;
      const formattedMinutes = parseInt(minutes) < 10 ? `0${minutes}` : minutes;

      return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
    },

    durationFormat(duration) {
      const hours = duration.hours;
      const minutes = duration.minutes;

      const formattedHours = parseInt(hours) < 10 ? `0${hours}` : hours;
      const formattedMinutes = parseInt(minutes) < 10 ? `0${minutes}` : minutes;

      return `${formattedHours}:${formattedMinutes}`;
    },
  },
  async mounted() {
    console.log(this.$route.params.id);
    await this.teachersStore.getTeachersWithoutPagination();
    const group = await this.groupsStore.getGroup(this.$route.params.id);
    this.group = {
      name: group.name,
      id: group.id,
      teacher_id: group?.teacher?.user?.id,
    };
  },
  validations() {
    return {
      live_title: {
        required: helpers.withMessage("live title is required", required),
        minLength: helpers.withMessage(
          "live title must have at least 3 characters",
          minLength(3)
        ),
      },
      start_date: {
        required: helpers.withMessage("start date is required", required),
      },
      duration: {
        required: helpers.withMessage("duration is required", required),
      },
    };
  },
  components: {
    VueDatePicker,
    LoaderModal,
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
<style>
.dp__pointer {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
</style>
