<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <GroupLoaderModal :isNewGroup="false" v-if="groupsStore.isLoadingModal" />
    <div class="my-6 mt-24 w-full">
      <InformationForm
        :groupInfo="groupInfo"
        @getGroupInfo="getGroupInfo"
        @updateValidity="updateGroupInfoValidity"
        :isValid="groupInfoValid"
        :isNew="false"
      />

      <button
        :disabled="!groupInfoValid"
        :class="`btn btn bg-cyan font-bold my-2 ${
          !groupInfoValid ? 'opacity-50' : 'opacity-100'
        }`"
        @click="updateGroup"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import InformationForm from "@/components/cards/groups/InformationForm.vue";
import { useCategoriesStore } from "@/store/categories";
import { useDestinationsStore } from "@/store/destinations";
import { useMeta } from "vue-meta";
import { useSubjectsStore } from "@/store/subjects";
import { useTeachersStore } from "@/store/teachers";
import GroupLoaderModal from "@/components/modals/GroupLoaderModal.vue";
import { useOptionsStore } from "@/store/options";
import { useGroupsStore } from "@/store/groups";

export default {
  setup() {
    useMeta({
      title: "Edit group",
      htmlAttrs: { lang: "en", amp: true },
    });
    const categoriesStore = useCategoriesStore();
    const groupsStore = useGroupsStore();
    const teachersStore = useTeachersStore();
    const destinationsStore = useDestinationsStore();
    const subjectsStore = useSubjectsStore();
    const optionsStore = useOptionsStore();
    return {
      categoriesStore,
      groupsStore,
      teachersStore,
      destinationsStore,
      subjectsStore,
      optionsStore,
    };
  },

  data() {
    return {
      groupInfoValid: false,
      groupInfoFormData: null,
      groupInfo: null,
    };
  },

  async mounted() {
    const group = await this.groupsStore.getGroup(this.$route.params.id);
    this.groupInfo = {
      cover: group.image ? group.image : null,
      group_name: group.name,
      group_date: group.group_date,
      description: group.description,
      teacher: group.teacher,
      destination: group.study_year,
      subject: group.module,
      option: group.option,
      price_per_session: group.price_per_session,
      sessions_per_month: group.sessions_per_month,
      prof_percentage: group.prof_percentage,
      max_seats: group.max_seats,
    };
    await Promise.all([
      this.teachersStore.getTeachersWithoutPagination(),
      this.destinationsStore.getDestinations(),
      this.subjectsStore.getSubjects(),
      this.optionsStore.getOptions(),
    ]);
  },

  methods: {
    updateGroupInfoValidity(isFormValid) {
      this.groupInfoValid = isFormValid;
    },

    getGroupInfo(formData) {
      this.groupInfoFormData = formData;
    },

    async updateGroup() {
      try {
        this.groupsStore.loaderMessage = "Updating group main data";
        this.groupsStore.isLoadingModal = true;
        await this.groupsStore.updateGroup(
          this.groupInfoFormData,
          this.$route.params.id
        );

        this.groupsStore.isLoadingModal = false;
        if (this.groupsStore.isUpdateSuccess) {
          this.$swal({
            icon: "success",
            title: "Group updated successfully",
            willClose: () => {
              this.groupsStore.addError = false;
              this.groupsStore.addSuccess = false;
              this.$router.push({ path: "/admin/groups" });
            },
          });
        } else if (this.groupsStore.isUpdateError) {
          this.$swal({
            icon: "error",
            html: `<ul>
            ${this.groupsStore.errorMessages.map((error) => {
              return error.map((message) => {
                return `<li class="text-red-500">${message}</li>`;
              });
            })}
          </ul>`,
            willClose: async () => {
              this.groupsStore.errorMessages = [];
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: "danger",
          title: "Error accured while updating the group!",
          html: `<ul>
            ${this.groupsStore.errorMessages.map((error) => {
              return error.map((message) => {
                return `<li class="text-red-500">${message}</li>`;
              });
            })}
          </ul>`,
          willClose: async () => {
            this.groupsStore.errorMessages = [];
          },
        });
      }
    },
  },

  components: {
    InformationForm,
    GroupLoaderModal,
  },
};
</script>
<style></style>
