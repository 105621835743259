<template>
  <div class="w-full mb-4">
    <div class="my-2 flex justify-between">
      <div>
        <h1 class="text-3xl text-black font-semibold">Groups</h1>
        <h1 v-if="isNew" class="text-md text-gray font-semibold">New Group</h1>
        <h1 v-else class="text-md text-gray font-semibold">Edit Group</h1>
      </div>
      <div v-if="groupsStore.newCourse">ADDED</div>
    </div>
    <div
      class="flex justify-between items-center bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
    >
      <span>Informations of the group</span>

      <svg
        v-if="!isValid"
        xmlns="http://www.w3.org/2000/svg"
        height="32"
        width="32"
        viewBox="0 0 512 512"
      >
        <circle
          cx="256"
          cy="256"
          r="248"
          stroke="#00DDAF"
          fill="none"
          stroke-width="16"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        height="32"
        width="32"
        viewBox="0 0 512 512"
      >
        <path
          d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM227.3 387.3l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L216 308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.2 16.4 6.2 22.6 0z"
          fill="#00DDAF"
        />
      </svg>
    </div>
    <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-b-xl">
      <div>
        <p class="text-purple mb-1 text-left">Cover</p>
      </div>
      <div class="my-2 mb-4 text-xl rounded-md">
        <label class="cursor-pointer w-fit flex items-center" for="InfoInput">
          <div
            v-if="!form.cover"
            class="flex flex-col upload-block justify-center items-center p-2 h-40 w-60 rounded-md border-dashed"
          >
            <p class="text-black mb-1 text-center text-base mt-2">
              Click here to select file
            </p>

            <input
              class="hidden"
              hidden
              id="InfoInput"
              @change="handleCoverChange"
              type="file"
              accept="image/jpeg,image/png,image/jpg"
            />
          </div>

          <img v-show="form.cover" class="h-40 w-60" ref="output" />
          <img
            @click="removeImage"
            :src="trash"
            class="ml-2"
            v-if="form.cover"
            width="40"
          />
        </label>
        <p class="text-red-500" v-if="v$.form?.cover?.$error">
          Cover is required
        </p>
      </div>
      <div class="md:flex">
        <div class="md:mr-6 my-3 md:w-4/12">
          <p class="text-purple mb-1 text-left">Group name</p>
          <input
            v-model="form.group_name"
            type="text"
            class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
          />
          <p class="text-red-500" v-if="v$.form?.group_name?.$error">
            Title must be at least 3 characters
          </p>
        </div>
        <div class="md:mr-6 my-3 md:w-4/12">
          <p class="text-purple mb-1 text-left">Group date</p>
          <input
            v-model="form.group_date"
            type="text"
            class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
          />
          <p class="text-red-500" v-if="v$.form?.group_date?.$error">
            Date is required!
          </p>
        </div>
        <div class="md:mr-6 my-3 md:w-4/12">
          <p class="text-purple mb-1 text-left">Teacher affectation</p>

          <!--<SelectTeacherDropdown @input="setActiveTeacher" />-->
          <GenericSelectDropdown
            v-model="form.teacher"
            :value="form.teacher"
            :options="this.teachersStore.teachers.data"
            :render="
              (teacher) =>
                teacher
                  ? teacher.user.first_name + ' ' + teacher.user.last_name
                  : 'Select an option'
            "
            @updateValue="getNewTeacher"
          />
          <p class="text-red-500" v-if="v$.form?.teacher?.$error">
            Teacher is required!
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="md:mr-6 my-3 md:w-4/12">
          <p class="text-purple mb-1 text-left">Subject ( المادة )</p>
          <GenericSelectDropdown
            v-model="form.subject"
            :value="form.subject"
            :options="this.subjectsStore.subjects"
            :render="
              (subject) => (subject ? subject.arabName : 'Select an option')
            "
            @updateValue="getNewSubject"
          />
          <p class="text-red-500" v-if="v$.form?.subject?.$error">
            Subject is required!
          </p>
        </div>
        <div class="md:mr-6 my-3 md:w-4/12">
          <p class="text-purple mb-1 text-left">Level</p>
          <GenericSelectDropdown
            v-model="form.destination"
            :value="form.destination"
            :options="this.destinationsStore.destinations"
            :render="
              (destination) => (destination ? destination : 'Select an option')
            "
            @updateValue="getNewDestination"
          />
          <p class="text-red-500" v-if="v$.form?.destination?.$error">
            Level is required!
          </p>
        </div>
        <div class="md:mr-6 my-3 md:w-4/12">
          <p class="text-purple mb-1 text-left">Option</p>
          <SelectMultipleOptionsDropdown
            @getOptions="getOptions"
            :options="this.optionsStore.optionsMulti"
            :formValue="form.option"
          />
          <!-- <GenericSelectDropdown
            v-model="form.option"
            :value="form.option"
            :options="optionsStore.options"
            :render="(option) => (option ? option.name : 'Select an option')"
            @updateValue="getNewOption"
          /> -->
          <p class="text-red-500" v-if="v$.form?.option?.$error">
            Level is required!
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="md:w-4/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Price per session</p>
          <input
            v-model="form.price_per_session"
            min="0"
            type="number"
            class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
          />
          <p class="text-red-500" v-if="v$.form?.price_per_session?.$error">
            Price is required
          </p>
        </div>
        <div class="md:w-4/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Session per month</p>
          <input
            v-model="form.sessions_per_month"
            min="0"
            type="number"
            class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
          />
          <p class="text-red-500" v-if="v$.form?.sessions_per_month?.$error">
            Session per month is required
          </p>
        </div>
        <div class="md:w-4/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Teacher percentage</p>
          <input
            v-model="form.prof_percentage"
            min="0"
            type="number"
            class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
          />
          <p class="text-red-500" v-if="v$.form?.prof_percentage?.$error">
            Percentage is required
          </p>
        </div>
        <div class="md:w-4/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Max seats</p>
          <input
            v-model="form.max_seats"
            min="0"
            type="number"
            class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
          />
          <p class="text-red-500" v-if="v$.form?.max_seats?.$error">
            Max seats is required
          </p>
        </div>
      </div>
      <div class="md:flex"></div>
      <div class="">
        <div class="my-3">
          <p class="text-purple mb-1 text-left">Description</p>
          <textarea
            name="description"
            class="w-full focus:outline-purple p-2 border border-purple rounded-xl"
            id=""
            cols="30"
            rows="5"
            v-model="form.description"
          ></textarea>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { useDestinationsStore } from "../../../store/destinations";
import { useSubjectsStore } from "../../../store/subjects";
import { useOptionsStore } from "../../../store/options";
import { useTeachersStore } from "../../../store/teachers";
import { useCategoriesStore } from "../../../store/categories";
import { useLevelsStore } from "../../../store/levels";
import trash from "@/assets/icons/cards/trash.svg";
import { required, minLength } from "@vuelidate/validators";
import GenericSelectDropdown from "../partials/GenericSelectDropdown.vue";
import SelectMultipleOptionsDropdown from "../groups/SelectMultipleOptionsDropdown.vue";
import { useGroupsStore } from "@/store/groups";

export default {
  props: {
    isValid: {
      type: Boolean,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    groupInfo: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      form: {
        cover: null,
        group_name: "",
        group_date: "",
        description: "",
        teacher: null,
        destination: null,
        subject: null,
        option: null,
        price_per_session: null,
        sessions_per_month: null,
        prof_percentage: null,
        max_seats: null,
      },
      currentTeacher: null,
      currentSubject: null,
      currentDestination: null,
      currentOption: null,
    };
  },

  setup() {
    const destinationsStore = useDestinationsStore();
    const subjectsStore = useSubjectsStore();
    const optionsStore = useOptionsStore();
    const teachersStore = useTeachersStore();
    const categoriesStore = useCategoriesStore();
    const groupsStore = useGroupsStore();
    const levelsStore = useLevelsStore();
    return {
      v$: useVuelidate(),
      destinationsStore,
      subjectsStore,
      trash,
      teachersStore,
      categoriesStore,
      groupsStore,
      optionsStore,
      levelsStore,
    };
  },
  watch: {
    currentTeacher: {
      handler(newTeacher) {
        this.form = {
          ...this.form,
          teacher_id: newTeacher?.user?.id,
        };
      },
    },
    currentSubject: {
      handler(newSubject) {
        this.form = {
          ...this.form,
          subject: newSubject?.id,
        };
      },
    },
    currentDestination: {
      handler(newDestination) {
        this.form = {
          ...this.form,
          destination: newDestination,
        };
      },
    },
    currentOption: {
      handler(newOption) {
        this.form = {
          ...this.form,
          option: newOption,
        };
      },
    },
    groupInfo: {
      handler() {
        this.form = {
          group_name: this.groupInfo.group_name
            ? this.groupInfo.group_name
            : "",
          group_date: this.groupInfo.group_date
            ? this.groupInfo.group_date
            : "",
          description: this.groupInfo.description
            ? this.groupInfo.description
            : "",
          cover: this.groupInfo.cover ? this.groupInfo.cover : null,
          teacher: this.groupInfo.teacher ? this.groupInfo.teacher : null,
          destination: this.groupInfo.destination
            ? this.groupInfo.destination
            : null,
          subject: this.groupInfo.subject ? this.groupInfo.subject : null,
          option: this.groupInfo.option ? this.groupInfo.option : null,
          price_per_session: this.groupInfo.price_per_session
            ? this.groupInfo.price_per_session
            : 0,
          sessions_per_month: this.groupInfo.sessions_per_month
            ? this.groupInfo.sessions_per_month
            : 0,
          prof_percentage: this.groupInfo.prof_percentage
            ? this.groupInfo.prof_percentage
            : 0,
          max_seats: this.groupInfo.max_seats ? this.groupInfo.max_seats : null,
        };
        if (this.groupInfo.cover) {
          this.$refs.output.src = this.form.cover;
          this.$refs.output.width = 200;
        }
      },
      deep: true,
    },
    form: {
      async handler() {
        const result = await this.v$.$validate();

        if (!result) {
          this.$emit("updateValidity", false);
          return;
        } else {
          this.addGroup();
          this.$emit("updateValidity", true);
          return;
        }
      },
      deep: true,
    },
  },

  methods: {
    getNewTeacher(nt) {
      this.form.teacher = nt;
    },
    getNewCategory(nc) {
      this.form.category = nc;
    },
    getNewSubject(ns) {
      this.form.category = ns;
    },
    getNewDestination(nd) {
      this.form.destination = nd;
    },
    getOptions(no) {
      this.form.option = no;
    },
    getNewOptions(no) {
      this.form.option = no;
    },
    handleCoverChange(event) {
      this.form.cover = event.target.files[0];
      let outputPic = this.$refs.output;
      // this.categoriesStore.editedCategory.image = this.cover;
      outputPic.src = URL.createObjectURL(this.form.cover);
      outputPic.width = 200;
    },
    removeImage() {
      this.$refs.output.src = null;
      this.form.cover = null;
      // this.categoriesStore.editedCategory.image = null;
    },
    addGroup() {
      const formData = new FormData();
      if (this.form.cover) {
        formData.append("image", this.form.cover);
      }
      formData.append("name", this.form.group_name);
      formData.append("group_date", this.form.group_date);
      formData.append("module_id", this.form.subject.id);
      if (this.form.option) {
        for (const option_id of this.form.option) {
          formData.append("option_id[]", option_id);
        }
      }
      formData.append("study_year", this.form.destination);
      formData.append(
        "price_per_session",
        parseInt(this.form.price_per_session)
      );
      formData.append("prof_percentage", parseInt(this.form.prof_percentage));
      formData.append("max_seats", parseInt(this.form.max_seats));
      formData.append(
        "sessions_per_month",
        parseInt(this.form.sessions_per_month)
      );
      formData.append("teacher_id", this.form.teacher.user.id);
      formData.append("description", this.form.description);
      this.$emit("getGroupInfo", formData);
    },
  },

  validations() {
    return {
      form: {
        group_name: { required, minLength: minLength(3) },
        group_date: { required },
        teacher: { required },
        destination: { required },
        subject: { required },
        price_per_session: { required },
        sessions_per_month: { required },
        prof_percentage: { required },
        max_seats: { required },
      },
    };
  },
  components: {
    // SelectTeacherDropdown,
    // SelectDestinationsDropdown,
    // SelectCategoryDropdown,
    GenericSelectDropdown,
    // SelectLevelDropdown,
    SelectMultipleOptionsDropdown,
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
